<template>
  <div class="user">
    <FormView :formData="fromData" :formLabels="formLabels" :column="formColumn" :labelWidth="labelWidth">
      <el-button type="success" @click="add">新增退款原因</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
      <el-button type="primary" @click="search">查询</el-button>
    </FormView>
    <TableView :loading="loading" :columns="columns" :data="rTableData" :pagination="pagination"
      :is-pagination-show="isPaginationShow" @getData="getDataList">
      <template #action>
        <el-table-column label="操作" align="center">
          <template #default="scoped">
            <el-button type="primary" size="small">
              编辑
            </el-button>
            <el-button type="primary" size="small">
              删除
            </el-button>
          </template>
        </el-table-column>
      </template>
    </TableView>
    <el-dialog v-model="reasonVisible" title="原因" width="30%" :before-close="reasonClose">
      <el-form ref="reasonFormRef" :model="reasonForm" :rules="rules" label-width="120px" class="reasonForm" status-icon>
        <el-form-item label="退款类型">
          <el-select placeholder="请选择">
            <el-option label="Zone one" value="shanghai" />
            <el-option label="Zone two" value="beijing" />
          </el-select>
        </el-form-item>
        <el-form-item label="退款原因" placeholder="请输入">
          <el-input />
        </el-form-item>
        <el-form-item label="排序">
          <el-input />
        </el-form-item>
        <el-form-item label="是否显示">
          <el-switch />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="reasonSubmit"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { reactive, onMounted, toRefs } from 'vue'
import TableView from '@/components/TableView.vue'
import FormView from '@/components/FormView.vue'
import { ElMessageBox, ElMessage } from 'element-plus'
const initData = reactive({
  formLabels: [
    {
      label: 'keywords',
      title: '搜索退款原因',
      placeholder: '请输入'
    },
    {
      title: '显示状态',
      label: 'status',
      type: 'options',
      options: [
        { value: 1, label: '封号' },
        { value: 0, label: '正常' }
      ],
      placeholder: '请选择'
    }
  ],
  fromData: {
    // 表单数据
    keywords: '',
    status: '',
    member: '',
    type: ''
  },
  formColumn: 4,
  loading: false,
  pagination: {
    current: 1,
    size: 10,
    total: 0
  },
  isPaginationShow: true,
  rTableData: [],
  columns: [
    {
      index: true,
      indexMethod (index) {
        return index + 1
      },
      width: 70,
      label: '编号'
    },
    { prop: 'nickName', label: '退款原因' },
    { prop: 'phone', label: '退款类型' },
    { prop: 'typeName', label: '状态' },
    { prop: 'currentActiveIntegral', label: '排序' },
    { slot: 'action' }
  ],
  reasonVisible: false,
  reasonForm: {}
})

onMounted(() => {
  // getDataList()
})


// 重置
const reset = () => {
  fromData.value = {
    // 表单数据
    keywords: '',
    status: '',
    type: ''
  }
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 查询
const search = () => {
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 获取列表数据
const getDataList = (val, flag) => {
  loading.value = true
  if (flag == 'size') {
    pagination.value.size = val || 10
  } else {
    pagination.value.current = val || 1
  }
  const data = {
    current: pagination.value.current,
    size: pagination.value.size
  }
  if (fromData.value.keywords !== '') {
    data.word = fromData.value.keywords
  }
  if (fromData.value.status !== '') {
    data.banState = fromData.value.status
  }
  if (fromData.value.type !== '') {
    data.type = fromData.value.type
  }
  guestPage(data).then(({ data: res }) => {
    loading.value = false
    if (res.code == 200) {
      res.data.records.forEach(item => {
        item.stateName = item.banState == 1 ? '封号' : '正常'
        item.newUserName = item.newUser ? '是' : '否'
      })
      rTableData.value = res.data.records
      pagination.value.total = Number(res.data.pageInfo.total)
    } else {
      ElMessage.error(res.msg)
      rTableData.value = []
      pagination.value.total = 0
    }
  })
}

const add = () => {
  reasonVisible.value = true
}

const {
  loading,
  pagination,
  isPaginationShow,
  rTableData,
  columns,
  formLabels,
  fromData,
  formColumn,
  labelWidth,
  reasonVisible,
  reasonForm
} = toRefs(initData)
</script>
<style lang="scss" scoped>
.reasonForm {

  .el-input,
  .el-select {
    width: 230px;
  }
}
</style>
